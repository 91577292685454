import React, { useState, useEffect } from "react";
import axios from "axios";
import { message, Select, Spin, Button, Divider, Empty } from "antd";

const { Option } = Select;

const SelectWithRemoveUser = ({ listId, refrshfun }) => {
  const [sharedUsers, setSharedUsers] = useState([]); // Store shared users
  const [selectedUsers, setSelectedUsers] = useState([]); // Track selected users
  const [loading, setLoading] = useState(false); // Loading state for fetching users

  // Retrieve the token from localStorage
  const logindata = JSON.parse(localStorage.getItem("logindata"));
  const apiToken = logindata?.accessToken;

  useEffect(() => {
    const fetchSharedUsers = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          "https://usfarmdata.org/fastapi/list_function/?operation=getsharedusers",
          { listid: listId },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${apiToken}`,
            },
          }
        );

        if (response.data?.sharedUsers) {
          setSharedUsers(response.data.sharedUsers);
        } else {
          message.error("Unexpected API response.");
          setSharedUsers([]);
        }
      } catch (error) {
        console.error("Error fetching shared users:", error);
        message.error("Failed to fetch shared users.");
        setSharedUsers([]);
      } finally {
        setLoading(false);
      }
    };

    if (listId) {
      fetchSharedUsers();
    }
  }, [listId, apiToken]);

  const handleUnshareUsers = async () => {
    if (selectedUsers.length === 0) {
      message.warning("Please select users to unshare.");
      return;
    }

    const payload = {
      listids: listId,
      user_ids_to_unshare: selectedUsers.join(","),
    };

    try {
      const response = await axios.post(
        "https://usfarmdata.org/fastapi/list_function/?operation=unshareuser",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiToken}`,
          },
        }
      );

      if (response.status === 200) {
        message.success("Users unshared successfully!");
        setSharedUsers(
          sharedUsers.filter((user) => !selectedUsers.includes(user.UserId))
        );
        setSelectedUsers([]);
        if (typeof refrshfun === "function") {
          refrshfun(); // Call the refresh function passed as prop
        }
      }
    } catch (error) {
      message.error("Error unsharing users.");
    }
  };

  const handleClearSelection = () => {
    setSelectedUsers([]);
  };

  const handleChange = (value) => {
    setSelectedUsers(value);
  };

  return (
    <div className=" h-fullbg-white  rounded-lg shadow-md p-6 transform transition duration-300 hover:scale-105 hover:shadow-lg">
      {loading ? (
        <div className="flex justify-center items-center h-full">
          <Spin size="large" />
        </div>
      ) : (
        <>
          {sharedUsers.length > 0 ? (
            <>
              <div className="text-lg font-semibold text-slate-600 mb-4">
                Select users
              </div>
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                size="large"
                placeholder="Select users to unshare"
                value={selectedUsers}
                onChange={handleChange}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider style={{ margin: "8px 0" }} />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "8px",
                      }}
                    >
                      <Button
                        type="primary"
                        danger
                        onClick={handleUnshareUsers}
                        disabled={selectedUsers.length === 0}
                      >
                        Unshare
                      </Button>
                      <Button onClick={handleClearSelection}>Clear</Button>
                    </div>
                  </>
                )}
              >
                {sharedUsers.map((user) => (
                  <Option key={user.UserId} value={user.UserId}>
                    {user.FirstName} {user.LastName} ({user.Email || "No email"}
                    )
                  </Option>
                ))}
              </Select>
            </>
          ) : (
            <div className="flex items-center justify-center h-full">
              <Empty description="No shared users found" />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SelectWithRemoveUser;
