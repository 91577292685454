export const fetchUserList = async (username) => {
  try {
    if (!username) {
      return [];
    }

    // Retrieve logindata from localStorage and parse it
    const logindata = JSON.parse(localStorage.getItem("logindata"));
    
    if (!logindata) {
      console.error("Logindata not found in localStorage");
      return [];
    }

    const { accessToken, adminId } = logindata;

    if (!accessToken || !adminId) {
      console.error("Missing access token or admin ID in logindata");
      return [];
    }

    const response = await fetch(
      "https://usfarmdata.org/fastapi/user_function/?operation=getactiveuser",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`, // Include the Bearer token
        },
        body: JSON.stringify({
          adminid: adminId,
          searchtext: username,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const body = await response.json();

    return body.users.map((user) => ({
      label: user.username,
      value: user.userid,
    }));
  } catch (error) {
    console.error("Error fetching user list:", error);
    // Return a default value or handle the error as needed
    return [];
  }
};
