// hooks/useUnshareList.js
export const handleUnshareList = async ({
    logindata,
    unshareListID,
    setTrigger,
    setUnshareListID,
    setConfirmLoading,
    setIsUSLModalOpen,
    messageApi,
    setErrorMessage,
  }) => {
    try {
      if (unshareListID !== "") {
        setConfirmLoading(true);
        const apiToken = logindata?.accessToken;
  
        const response = await fetch(
          "https://usfarmdata.org/fastapi/list_function/?operation=unshare",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              accept: "application/json",
              Authorization: `Bearer ${apiToken}`,
            },
            body: JSON.stringify({
              listids: unshareListID,
            }),
          }
        );
  
        if (response.ok) {
          const result = await response.json();
          messageApi.open({
            type: "success",
            content: "Selected List unshared from other users.",
            className: "custom-message",
          });
          return result;
        } else {
          const errorText = await response.text();
          console.log(errorText);
          throw new Error("Server Error, please try again later.");
        }
      }
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Server Error, please try again later.",
        className: "custom-message",
      });
      throw error;
    } finally {
      setTrigger(true);
      setUnshareListID("");
      setConfirmLoading(false);
      setIsUSLModalOpen(false);
      setErrorMessage("");
    }
  };
  