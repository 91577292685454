import React, { useState, useEffect } from "react";
import { QueryBuilderAntD } from "@react-querybuilder/antd";
import { QueryBuilder, formatQuery } from "react-querybuilder";
import { Button, message, Tooltip, Select } from "antd";
import { Container, Box } from "@lib/Layout";
import CustomValueEditor from "./CustomValueEditor";
import useExecuteRule from "../../../api/useExecuteRule";
import SqlCodePreview from "@components/SqlCodePreview/SqlCodePreview";
import RuleTable from "../RuleTable/RuleTable";
const { Option } = Select;
const fields = [
  {
    name: "signupdate",
    label: "Signup date",
    valueEditorType: "custom",
    operators: [
      { name: "=", label: "=" },
      { name: "!=", label: "!=" },
      { name: "<", label: "<" },
      { name: ">", label: ">" },
      { name: "<=", label: "<=" },
      { name: ">=", label: ">=" },
      { name: "between", label: "between" },
    ],
  },
  {
    name: "Signedup",
    label: "Signed Up",
    valueEditorType: "custom",
    operators: [
      { name: "=", label: "=" },
      { name: "<", label: "<" },
      { name: ">", label: ">" },
      { name: "<=", label: "<=" },
      { name: ">=", label: ">=" },
    ],
  },
  {
    name: "notes",
    label: "Notes Date",
    valueEditorType: "custom",
    operators: [
      { name: "=", label: "=" },
      { name: "!=", label: "!=" },
      { name: "<", label: "<" },
      { name: ">", label: ">" },
      { name: "<=", label: "<=" },
      { name: ">=", label: ">=" },
      { name: "between", label: "between" },
    ],
  },
  {
    name: "callbacks",
    label: "Callbacks Date",
    valueEditorType: "custom",
    operators: [
      { name: "=", label: "=" },
      { name: "!=", label: "!=" },
      { name: "<", label: "<" },
      { name: ">", label: ">" },
      { name: "<=", label: "<=" },
      { name: ">=", label: ">=" },
      { name: "between", label: "between" },
    ],
  },

  {
    name: "product",
    label: "Product",
    valueEditorType: "select",
    values: [
      { name: "No", label: "No Purchase" },
      { name: "GoLeads", label: "GoLeads" },
      { name: "USFD", label: "USFD" },
      { name: "LGC", label: "LGC" },
    ],
    operators: [{ name: "=", label: "=" }],
  },
  {
    name: "Purchased",
    label: "Purchased",
    valueEditorType: "custom",
    operators: [
      { name: "=", label: "=" },
      { name: "<", label: "<" },
      { name: ">", label: ">" },
      { name: "<=", label: "<=" },
      { name: ">=", label: ">=" },
    ],
  },
  {
    name: "PurchaseDate",
    label: "Purchase Date",
    valueEditorType: "date",
    operators: [
      { name: "=", label: "=" },
      { name: "!=", label: "!=" },
      { name: "<", label: "<" },
      { name: ">", label: ">" },
      { name: "<=", label: "<=" },
      { name: ">=", label: ">=" },
      { name: "between", label: "between" },
    ],
  },

  // {
  //   name: "Purchase",
  //   label: "Purchase",
  //   valueEditorType: "custom",
  //   operators: [
  //     { name: "=", label: "=" },
  //     { name: "!=", label: "!=" },
  //     { name: "<", label: "<" },
  //     { name: ">", label: ">" },
  //     { name: "<=", label: "<=" },
  //     { name: ">=", label: ">=" },
  //     { name: "between", label: "between" },
  //   ],
  // },
  {
    name: "count",
    label: "Count",
    valueEditorType: "select",
    values: [
      { name: "yes", label: "Performed" },
      { name: "No", label: "Not Performed" },
    ],
    operators: [
      { name: "=", label: "=" },
      { name: "!=", label: "!=" },
    ],
  },
  {
    name: "quote",
    label: "Quote",
    valueEditorType: "custom",
    operators: [
      { name: "=", label: "=" },
      { name: "!=", label: "!=" },
      { name: "<", label: "<" },
      { name: ">", label: ">" },
      { name: "<=", label: "<=" },
      { name: ">=", label: ">=" },
      { name: "between", label: "between" },
    ],
  },
  {
    name: "list",
    label: "List",
    valueEditorType: "custom",
    operators: [
      { name: "=", label: "=" },
      { name: "!=", label: "!=" },
      { name: "<", label: "<" },
      { name: ">", label: ">" },
      { name: "<=", label: "<=" },
      { name: ">=", label: ">=" },
      { name: "between", label: "between" },
    ],
  },
  {
    name: "amountSpent",
    label: "Amount Spent",
    valueEditorType: "select",
    operators: [
      { name: "=", label: "=" },
      { name: "!=", label: "!=" },
      { name: "<", label: "<" },
      { name: ">", label: ">" },
      { name: "<=", label: "<=" },
      { name: ">=", label: ">=" },
      { name: "between", label: "between" },
    ],
  },
];

const EmailCreateRule = () => {
  const currentDate = new Date().toISOString().split("T")[0];

  const initialQuery = {
    rules: [
      {
        field: "signupdate",
        operator: "=",
        value: "",
      },
    ],
  };

  const [query, setQuery] = useState(initialQuery);
  const [ruleName, setRuleName] = useState("");
  const [liveQuery, setLiveQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [showCount, setShowCount] = useState(false);
  const [selectedRule, setSelectedRule] = useState(null);
  const [selectedRuleOption, setSelectedRuleOption] = useState(null);
  console.log(selectedRuleOption, "selectedRuleOption");
  const handleRuleChange = (value) => {
    setSelectedRuleOption(value.toUpperCase());
  };
  const [previousQuery, setPreviousQuery] = useState(initialQuery);
  const [showRuleTable, setShowRuleTable] = useState(false);
  const {
    dataLiveRule,
    loadingLiveRule,
    errorLiveRule,
    executeLiveRule,
    dataGetRule,
    loadingGetRule,
    errorGetRule,
    getRule,
  } = useExecuteRule();

  const handleQueryChange = (newQuery) => {
    setQuery(newQuery);
    setLiveQuery(formatQuery(newQuery, { format: "sql", parseNumbers: true }));
  };

  const loginData = JSON.parse(localStorage.getItem("logindata"));
  const UserId = loginData ? loginData.userId : "";

  useEffect(() => {
    getRule(UserId);
  }, []);

  const handleChange = (value) => {
    setSelectedRule(value);
    console.log("Selected Rule ID:", value);
  };

  const sql = formatQuery(query, { format: "sql", parseNumbers: true });
  console.log(sql, "sql");

  const handleSubmit = async () => {
    if (!ruleName) {
      message.error("Please enter a rule name before saving.");
      return;
    }

    if (!selectedRuleOption) {
      message.error("Please select a rule type before saving.");
      return;
    }

    const loginData = JSON.parse(localStorage.getItem("logindata"));
    const token = loginData ? loginData.accessToken : null;
    const userId = loginData ? loginData.userId : "";

    const requestData = {
      UserId: userId,
      RuleName: ruleName,
      RuleCriteria: liveQuery,
      SearchIn: selectedRuleOption,
    };

    setLoading(true);
    try {
      const response = await fetch(
        "https://usfarmdata.org/fastapi/email_function/?operation=createrule",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();
        setShowRuleTable(true);
        message.success("Rule saved successfully!");
        setQuery(initialQuery); // Reset query to initial state
        setRuleName("");
        selectedRuleOption(null);
        setShowCount(false);
        console.log("Success:", result);
      } else {
        throw new Error("Failed to save the rule.");
      }
    } catch (error) {
      message.error("Error saving the rule. Please try again.");
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleLiveRule = () => {
    executeLiveRule(liveQuery);
    setShowCount(true);
  };
  const mapRulesToOptions = (rules) => {
    return rules
      ? rules.map((rule) => ({ label: rule.RuleName, value: rule.RuleId }))
      : [];
  };
  // const options = mapRulesToOptions(dataGetRule);
  // const sqlQuery =
  //   "(signupdate between '2024-05-01' and '2024-11-01' and notes = 'No' or product = 'USFD' and count = 'yes')";

  // console.log(JSON.stringify(useParseSQLToJSON(sqlQuery), null, 2));
  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(liveQuery)
      .then(() => message.success("Live query copied to clipboard!"))
      .catch(() =>
        message.error("Failed to copy the query. Please try again.")
      );
  };

  return (
    <Container className="gap-2 bg-white p-2">
      <Box
        className="p-2 rounded-md bg-white"
        style={{
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
        }}
      >
        <div className="flex items-center justify-between gap-4">
          <div className="font-semibold text-slate-700">Create Rule</div>
          <Tooltip
            title={showRuleTable && "Click to back rule creation page."}
            placement="left"
          >
            <Button
              type="primary"
              ghost
              onClick={() => setShowRuleTable(!showRuleTable)}
            >
              {showRuleTable ? "Back" : "View Saved Rules"}
            </Button>
          </Tooltip>
        </div>
      </Box>
      {showRuleTable && (
        <Box flexible={loadingGetRule ? true : false}>
          <RuleTable
            loadingGetRule={loadingGetRule}
            errorGetRule={errorGetRule}
            dataGetRule={dataGetRule}
            getRule={getRule}
          />
        </Box>
      )}

      {!showRuleTable && (
        <>
          {" "}
          <Box flexible>
            <Container alignBox="row" className="gap-2">
              <Box
                flexible
                className="p-2 rounded-md bg-white"
                style={{
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
                }}
              >
                <Container>
                  <Box>
                    <div className="flex gap-4">
                      {/* Rule Name Input */}
                      <div
                        className="mt-3 mb-4 w-96 p-2 rounded-md"
                        style={{
                          boxShadow:
                            "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
                        }}
                      >
                        <label
                          htmlFor="rule_name"
                          className="block mb-1  font-medium text-slate-700"
                        >
                          Rule Name
                        </label>
                        <input
                          type="text"
                          id="rule_name"
                          className="border-b border-gray-300 focus:border-blue-500 text-gray-900 text-sm block w-full py-3 outline-none"
                          placeholder="Enter rule name..."
                          value={ruleName}
                          onChange={(e) => setRuleName(e.target.value)}
                          required
                        />
                      </div>

                      {/* Radio Buttons */}
                      <div
                        className="mt-3 mb-4 w-96 p-2 rounded-md"
                        style={{
                          boxShadow:
                            "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
                        }}
                      >
                        <label className="block mb-2  font-medium text-slate-700">
                          Select List Type
                        </label>

                        <Select
                          value={selectedRuleOption}
                          onChange={handleRuleChange}
                          className="w-full"
                          placeholder="Choose List"
                        >
                          <Option value="database">Database List</Option>
                          <Option value="customerbase">Customer List</Option>
                        </Select>

                        {/* Description */}
                        <div className="mt-1 text-sm text-green-700">
                          {selectedRuleOption === "DATABASE" && (
                            <p>
                              The rule will be applied to the selected database
                              list.
                            </p>
                          )}
                          {selectedRuleOption === "CUSTOMERBASE" && (
                            <p>
                              The rule will be applied to the selected customer
                              list.
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </Box>

                  <Box flexible scroll="vertical">
                    <QueryBuilderAntD>
                      <QueryBuilder
                        fields={fields}
                        query={query}
                        onQueryChange={handleQueryChange}
                        showCloneButtons
                        showIndependentCombinators
                        showBranches
                        controlClassnames={{
                          queryBuilder: "queryBuilder-branches",
                        }}
                        controlElements={{ valueEditor: CustomValueEditor }}
                      />
                    </QueryBuilderAntD>
                  </Box>
                </Container>
              </Box>
              <Box
                style={{
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
                }}
                className="rounded-md pb-2 w-1/3"
              >
                <Container>
                  <Box className="text-base text-slate-700 font-semibold p-2 border-b">
                    Live Query
                  </Box>

                  {showCount && (
                    <div className="flex gap-4 justify-center items-center">
                      <div
                        className="mt-3 mb-4 w-80 p-5 rounded-md  "
                        style={{
                          boxShadow:
                            "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
                        }}
                      >
                        {loadingLiveRule && (
                          <div className="h-full flex flex-col gap-2 justify-center items-center">
                            <div role="status">
                              <svg
                                aria-hidden="true"
                                className="inline w-8 h-8 text-gray-200 animate-spin fill-green-500"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                  fill="currentFill"
                                />
                              </svg>
                            </div>
                            <span className="text-slate-600 text-sm">
                              Fetching Count... Please wait.
                            </span>
                          </div>
                        )}

                        {errorLiveRule && (
                          <div
                            class="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:text-red-400"
                            role="alert"
                          >
                            {errorLiveRule}
                          </div>
                        )}

                        {dataLiveRule && (
                          <p className="h-full flex-col flex justify-center items-center gap-1 ">
                            <span className="text-slate-700">
                              Rule Live Count
                            </span>

                            <span className="text-blue-500 font-semibold text-4xl">
                              {dataLiveRule === null ? (
                                <span>0</span>
                              ) : (
                                dataLiveRule.count
                              )}
                            </span>
                          </p>
                        )}
                      </div>
                    </div>
                  )}

                  <Box flexible scroll="vertical" className="p-3">
                    <div
                      className="mt-3 mb-4 max-w-lg p-2"
                      onClick={handleCopyToClipboard}
                    >
                      {/* <span
                    onClick={handleCopyToClipboard}
                    className="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-1 rounded dark:bg-blue-700 dark:text-white cursor-pointer"
                  >
                    Copy
                  </span> */}
                      <Tooltip title="Click To Copy">
                        {" "}
                        <SqlCodePreview sqlString={liveQuery} />
                      </Tooltip>
                    </div>
                  </Box>
                </Container>
              </Box>
            </Container>
          </Box>
          <Box
            className="bg-white p-2 rounded-md"
            style={{
              boxShadow:
                "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
            }}
          >
            <div className="flex items-center gap-3">
              <Button type="primary" onClick={handleSubmit} loading={loading}>
                {loading ? "Saving rule..." : "Save Rule"}
              </Button>

              <Button type="primary" onClick={handleLiveRule} ghost>
                View Count
              </Button>
            </div>
          </Box>
        </>
      )}
    </Container>
  );
};

export default EmailCreateRule;
