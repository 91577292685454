import axios from "axios";
import React from "react";
import { Tooltip } from "antd";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import CampaignIcon from "@mui/icons-material/Campaign";

// Component to represent primary shared lists
const PrimarySharedList = () => {
  return (
    <div className="flex items-center gap-1">
      <div className="rounded-full h-5 w-5 bg-green-600 text-white">
        <span className="flex items-center justify-center text-sm">S</span>
      </div>
    </div>
  );
};

// Component to represent secondary shared lists
const SecondarySharedList = () => {
  return (
    <div className="flex items-center gap-1">
      <div className="rounded-full h-5 w-5 bg-red-600 text-white">
        <Tooltip title="Shared List">
          <span className="flex items-center justify-center text-sm">S</span>
        </Tooltip>
      </div>
    </div>
  );
};

export const fetchCustomerListData = async (
  setLoading,
  setapiTblData,
  showUSLModal,
  showDLModal,
  ViewContactNavigate
) => {
  try {
    setLoading(true);
    const logindata = JSON.parse(localStorage.getItem("logindata"));
    const apiuserID = logindata?.userId || 1005;

    // Mock data for testing
    const mockData = [
      {
        listid: 7002,
        userid: 1005,
        sharedlistflag: true,
        mergedlistflag: false,
        listcount: 250,
        shareduserid: "#1083#",
        listname: "US Customer List",
        listdate: "08-05-2024",
        liststatus: "S",
      },
      {
        listid: 7003,
        userid: 1005,
        sharedlistflag: false,
        mergedlistflag: false,
        listcount: 23,
        shareduserid: null,
        listname: "US Prospect List",
        listdate: "08-06-2024",
        liststatus: "S",
      },
    ];

    // Process and format data
    const formattedData = mockData.map((item) => {
      let sharedContent = "";
      let cancelListshare = "";

      // Determine shared content and "Unshare List" icon
      if (item.sharedlistflag) {
        if (apiuserID === item.userid) {
          sharedContent = <PrimarySharedList />;
          cancelListshare = (
            <Tooltip title="Unshare this list">
              <CancelScheduleSendIcon
                className="tblunshareicon"
                fontSize="small"
                onClick={() => showUSLModal(item.listid)}
              />
            </Tooltip>
          );
        } else {
          sharedContent = <SecondarySharedList />;
        }
      }

      return {
        key: item.listid,
        userid: item.userid,
        isshared: item.sharedlistflag ? 1 : 0,
        shared: sharedContent,
        listcount: item.listcount,
        listname: (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault(); // Prevent the default anchor behavior
              ViewContactNavigate(item);
            }}
            className="text-blue-500 block"
          >
            <Tooltip title="Click to view contact list details">{`${item.listname} (${item.listcount})`}</Tooltip>
          </a>
        ),
        listdate: new Date(item.listdate).toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }),
        viewlist: (
          <Tooltip title="View this list">
            <RemoveRedEyeIcon
              className="tblviewicon"
              fontSize="small"
              onClick={() => ViewContactNavigate(item)}
            />
          </Tooltip>
        ),
        removelist:
          apiuserID === item.userid ? (
            <Tooltip title="Delete this list">
              <DeleteIcon
                className="tbldeleteicon"
                fontSize="small"
                onClick={() => showDLModal(item.listid)}
              />
            </Tooltip>
          ) : (
            ""
          ),
        cancelshare: cancelListshare,
        deploypostcard: (
          <Tooltip title="Deploy Postcard">
            <ContactMailIcon className="tbldeployicon" fontSize="small" />
          </Tooltip>
        ),
        campaign: (
          <Tooltip title="Run Campaign">
            <CampaignIcon className="tblcampaignicon" fontSize="small" />
          </Tooltip>
        ),
      };
    });

    setapiTblData(formattedData);
  } catch (error) {
    console.error("Error fetching list data:", error);
    setapiTblData([]);
  } finally {
    setLoading(false);
  }
};
