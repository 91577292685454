// import { useState, useEffect } from "react";

// const useFetchAllSchedule = (refresh) => {
//   const [scheduledData, setScheduledData] = useState(null);
//   const [scheduleLoading, setScheduleLoading] = useState(true);
//   const [scheduleError, setScheduleError] = useState(null);

//   useEffect(() => {
//     const fetchRule = async () => {
//       try {
//         setScheduleLoading(true);
//         const loginData = JSON.parse(localStorage.getItem("logindata"));
//         const token = loginData ? loginData.accessToken : null;
//         const UserId = loginData ? loginData.userId : null;

//         const response = await fetch(
//           "https://usfarmdata.org/fastapi/email_function/?operation=getallschedules",
//           {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//               Authorization: `Bearer ${token}`,
//             },
//             body: JSON.stringify({ Userid: UserId }),
//           }
//         );

//         if (!response.ok) {
//           throw new Error(`Error: ${response.status}`);
//         }

//         const result = await response.json();
//         console.log(result, "result");
//         setScheduledData(result);
//       } catch (err) {
//         setScheduleError(err.message);
//       } finally {
//         setScheduleLoading(false);
//       }
//     };

//     fetchRule();
//   }, [refresh]);

//   return { scheduledData, scheduleLoading, scheduleError };
// };

// export default useFetchAllSchedule;

import { useState, useEffect } from "react";

const useFetchAllSchedule = (refresh) => {
  const [scheduledData, setScheduledData] = useState(null);
  const [recurringSchedules, setRecurringSchedules] = useState(null);
  const [scheduleLoading, setScheduleLoading] = useState(true);
  const [recurringLoading, setRecurringLoading] = useState(true);
  const [scheduleError, setScheduleError] = useState(null);
  const [recurringError, setRecurringError] = useState(null);

  useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem("logindata"));
    const token = loginData ? loginData.accessToken : null;
    const UserId = loginData ? loginData.userId : null;

    const fetchSchedules = async (operation, setData, setError, setLoading) => {
      try {
        setLoading(true);
        const response = await fetch(
          `https://usfarmdata.org/fastapi/email_function/?operation=${operation}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ Userid: UserId }),
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const result = await response.json();
        setData(result);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSchedules(
      "getallschedules",
      setScheduledData,
      setScheduleError,
      setScheduleLoading
    );
    fetchSchedules(
      "getallrecurringschedules",
      setRecurringSchedules,
      setRecurringError,
      setRecurringLoading
    );
  }, [refresh]);

  return {
    scheduledData,
    recurringSchedules,
    scheduleLoading,
    recurringLoading,
    scheduleError,
    recurringError,
  };
};

export default useFetchAllSchedule;
