// hooks/useShareList.js
export const handleShareList = async ({
    logindata,
    selectuservalue,
    selectedListIDs,
    setErrorMessage,
    setConfirmLoading,
    setTrigger,
    setIsSLModalOpen,
    messageApi,
  }) => {
    setErrorMessage("");
    let sUserstoShare = "";
  
    try {
      setConfirmLoading(true);
      const apiToken = logindata?.accessToken;
  
      if (selectuservalue && selectuservalue.length > 0) {
        sUserstoShare = selectuservalue.reduce(
          (arrval, item) =>
            arrval ? `${arrval},${item.value}` : `${item.value}`,
          null
        );
      } else {
        throw new Error("Please select at least one user to share.");
      }
  
      const url = "https://usfarmdata.org/fastapi/list_function/?operation=share";
  
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: `Bearer ${apiToken}`,
        },
        body: JSON.stringify({
          listids: selectedListIDs,
          user_ids_to_share: sUserstoShare,
        }),
      });
  
      if (response.ok) {
        const result = await response.json();
        setTrigger(true);
        setIsSLModalOpen(false);
        setErrorMessage("");
        messageApi.open({
          type: "success",
          content: "List shared successfully.",
          className: "custom-message",
        });
        return result;
      } else {
        const errorText = await response.text();
        console.error(errorText);
        throw new Error("Server Error, please try again later.");
      }
    } catch (error) {
      setErrorMessage(error.message);
      setConfirmLoading(false);
      throw error;
    }
  };
  