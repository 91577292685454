import { Select, message, Tooltip, Button } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

const statusOptions = [
  { label: "Active", value: "active", color: "green" },
  { label: "On Hold", value: "suspended", color: "orange" },
];

const RecurringScheduleColumns = (setRefresh, RecurringViewReport) => [
  {
    title: "Schedule Name",
    dataIndex: "Schedulename",
    key: "Schedulename",
    width: "20%",
    needSorting: false,
    needSearch: false,
  },
  {
    title: "Description",
    dataIndex: "Description",
    key: "Description",
    width: "7%",
    needSorting: false,
    align: "center",
    render: (description) => (
      <Tooltip
        color="blue"
        overlayStyle={{ maxWidth: "400px" }}
        title={
          <div
            style={{
              maxHeight: "200px",
              overflowY: "auto",
            }}
          >
            {description || "No Description available"}
          </div>
        }
        trigger="hover"
      >
        <EyeOutlined style={{ fontSize: 18, cursor: "pointer" }} />
      </Tooltip>
    ),
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "schdulestatus",
    width: "10%",
    render: (status, record) => {
      const selectedOption =
        statusOptions.find((opt) => opt.value === status.toLowerCase()) || {};
      const statusColor = selectedOption.color || "defaultColor";

      const isDisabled = status.toLowerCase() === "completed";

      return (
        <Select
          defaultValue={status.toLowerCase()}
          style={{ width: "100%" }}
          options={statusOptions.map((option) => ({
            value: option.value,
            label: <span style={{ color: option.color }}>{option.label}</span>,
          }))}
          onChange={(newStatus) =>
            handleStatusChange(record.Scheduleid, newStatus, setRefresh)
          }
          disabled={isDisabled}
        />
      );
    },
  },
  {
    title: "Created Date",
    dataIndex: "Createddate",
    key: "Createddate",
    width: "10%",
    sorter: (a, b) => new Date(a.Createddate) - new Date(b.Createddate),
    render: (date) =>
      date ? dayjs(date).format("YYYY/MM/DD") : "No Date Provided",
    sortDirections: ["descend", "ascend"],
    needSorting: true,
  },
  {
    title: "Start Date",
    dataIndex: "Startdate",
    key: "Startdate",
    width: "10%",
    sorter: (a, b) => new Date(a.Startdate) - new Date(b.Startdate),
    render: (date) =>
      date ? dayjs(date).format("YYYY/MM/DD") : "No Date Provided",
    sortDirections: ["descend", "ascend"],
    needSorting: true,
  },
  {
    title: "End Date",
    dataIndex: "Enddate",
    key: "Enddate",
    width: "10%",
    sorter: (a, b) => new Date(a.Enddate) - new Date(b.Enddate),
    render: (date) =>
      date ? dayjs(date).format("YYYY/MM/DD") : "No Date Provided",
    sortDirections: ["descend", "ascend"],
    needSorting: true,
  },
  {
    title: "RunTime",
    dataIndex: "RunTime",
    key: "RunTime",
    width: "10%",
    sorter: (a, b) =>
      new Date(`1970-01-01T${a.RunTime}Z`) -
      new Date(`1970-01-01T${b.RunTime}Z`),
    render: (time) => time || "No Time Provided",
    sortDirections: ["descend", "ascend"],
    needSorting: true,
  },
  {
    title: "Actions",
    key: "actions",
    width: "10%",
    render: (_, record) => (
      <Button type="primary" onClick={() => RecurringViewReport(record)}>
        View Report
      </Button>
    ),
  },
];

const handleStatusChange = async (scheduleId, newStatus, setRefresh) => {
  try {
    const loginData = JSON.parse(localStorage.getItem("logindata"));
    const token = loginData?.accessToken;

    const response = await fetch(
      `https://usfarmdata.org/fastapi/email_function/?operation=updateschedule`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          Scheduleid: scheduleId,
          Status: newStatus,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to update schedule status");
    }

    message.success("Schedule status updated successfully.");
    setRefresh((prev) => !prev);
  } catch (error) {
    console.error("Error updating schedule status:", error);
    message.error("Failed to update schedule status.");
  }
};

export default RecurringScheduleColumns;
