import axios from "axios";
import React from "react";
import Tooltip from "@mui/material/Tooltip";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import CampaignIcon from "@mui/icons-material/Campaign";

// PrimarySharedList Component
const PrimarySharedList = () => {
  return (
    <div className="flex items-center gap-1">
      <div className="rounded-full h-5 w-5 bg-green-600 text-white">
        <span className="flex items-center justify-center text-sm">S</span>
      </div>
    </div>
  );
};

// SecondarySharedList Component
const SecondarySharedList = () => {
  return (
    <div className="flex items-center gap-1">
      <div className="rounded-full h-5 w-5 bg-red-600 text-white">
        <Tooltip title="Shared List">
          <span className="flex items-center justify-center text-sm">S</span>
        </Tooltip>
      </div>
    </div>
  );
};

// applyFilter Function
export const applyFilter = async (
  chipLNvalues,
  chipLCvalues,
  chipLTvalues,
  chipDatevalues,
  logindata,
  setLoading,
  setapiTblData,
  showUSLModal,
  showDLModal,
  ViewContactNavigate
) => {
  let listNames = "";
  let listCounts = "";
  let listTypeParam = "";
  let listDateFrom = "";
  let listDateTo = "";

  if (chipLNvalues?.lncriterias) {
    listNames = chipLNvalues.lncriterias.join(",");
  }

  if (chipLCvalues?.lccriterias) {
    listCounts = chipLCvalues.lccriterias.join(",");
  }

  if (chipLTvalues?.ltcriterias) {
    const listType = chipLTvalues.ltcriterias.join(",");
    if (listType === "All Lists") {
      listTypeParam = "A";
    } else if (listType === "Shared Lists") {
      listTypeParam = "S";
    } else if (listType === "My Lists") {
      listTypeParam = "M";
    }
  }

  if (chipDatevalues?.datecriterias) {
    const listDates = chipDatevalues.datecriterias.join(",");
    const datesArray = listDates.split(" to ");
    if (datesArray.length === 2) {
      listDateFrom = datesArray[0];
      listDateTo = datesArray[1];
    }
  }

  try {
    setLoading(true);

    const apiToken = logindata?.accessToken;
    const userID = logindata?.userId;

    const response = await axios.post(
      "https://usfarmdata.org/fastapi/list_function/?operation=get",
      {
        listname: listNames,
        listcount: listCounts,
        listtype: listTypeParam,
        listdatefrom: listDateFrom,
        listdateto: listDateTo,
        userid: userID,
        shareduserid: "",
        mergedlistflag: "",
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiToken}`,
        },
      }
    );

    const data = response.data;

    const formattedData = data.map((item) => {
      let sharedContent = "";
      let cancelListshare = "";
      if (item.sharedlistflag) {
        if (userID === item.userid) {
          sharedContent = <PrimarySharedList />;
          cancelListshare = (
            <CancelScheduleSendIcon
              className="tblunshareicon"
              fontSize="small"
              onClick={() => showUSLModal(item.listid)}
            />
          );
        } else {
          sharedContent = <SecondarySharedList />;
        }
      }

      return {
        key: item.listid,
        userid: item.userid,
        isshared: item.sharedlistflag ? 1 : 0,
        ismerged: item.mergedlistflag ? 1 : 0,
        shared: sharedContent,
        listcount: item.listcount,
        shareduserids: item.shareduserids,
        listname: `${item.listname} (${item.listcount})`,
        listdate: new Date(item.listdate).toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }),
        viewlist: (
          <Tooltip title="View this list">
            <RemoveRedEyeIcon
              className="tblviewicon"
              fontSize="small"
              onClick={() => ViewContactNavigate(item.listid)}
            />
          </Tooltip>
        ),
        removelist:
          userID === item.userid ? (
            <Tooltip title="Delete this list">
              <DeleteIcon
                className="tbldeleteicon"
                fontSize="small"
                onClick={() => showDLModal(item.listid)}
              />
            </Tooltip>
          ) : (
            ""
          ),
        cancelshare: cancelListshare,
        deploypostcard: (
          <ContactMailIcon className="tbldeployicon" fontSize="small" />
        ),
        campaign: (
          <CampaignIcon className="tblcampaignicon" fontSize="small" />
        ),
      };
    });

    setapiTblData(formattedData);
  } catch (error) {
    console.error("Error applying filter:", error);
    setapiTblData(null);
  } finally {
    setLoading(false);
  }
};
