import React, { useState, useEffect } from "react";
import { Container, Box } from "@lib/Layout";

import {
  Button,
  Form,
  Select,
  Input,
  DatePicker,
  TimePicker,
  Row,
  Col,
  message,
} from "antd";
import dayjs from "dayjs";
import isEqual from "lodash/isEqual";
import useExecuteRule from "../../../api/useExecuteRule";
import PreviewTemplate from "../PreviewTemplate/PreviewTemplate";
import PreviewRule from "../PreviewRule/PreviewRule";
import ScheduleTable from "../ScheduleTable/ScheduleTable";
import useAddSchedule from "../../../api/useAddSchedule";
import useUpdateSchedule from "../../../api/useUpdateSchedule";
import { DarkModeSharp } from "@mui/icons-material";
const { TextArea } = Input;

const Schedule = () => {
  const [activeTab, setActiveTab] = useState("history");
  const [selectedView, setSelectedView] = useState("onetime");
  const [templates, setTemplates] = useState([]);
  const [tembLoading, settembLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedValues, setSelectedValues] = useState({});
  const [showSelectedValues, setShowSelectedValues] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState({});
  const [form] = Form.useForm();
  const { Option } = Select;

  const loginData = JSON.parse(localStorage.getItem("logindata"));
  const Token = loginData ? loginData.accessToken : null;
  const userId = loginData ? loginData.userId : "";
  const editScheduleId = JSON.parse(
    localStorage.getItem("editScheduleIdOneTime")
  );
  const editRecurringScheduleId = JSON.parse(
    localStorage.getItem("editScheduleIdRecurring")
  );
  const isRecurring =
    selectedView !== "onetime" || editRecurringScheduleId ? true : false;

  useEffect(() => {
    if (editScheduleId) {
      setSelectedView("onetime");
    } else if (editRecurringScheduleId) {
      setSelectedView("recurring");
    }
  }, [editScheduleId, editRecurringScheduleId]);

  const handleViewChange = (e) => {
    setSelectedView(e.target.value);
  };

  const handleSubmit = (values) => {
    console.log("Form Values:", values);
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  useEffect(() => {
    fetchScheduleData();
  }, [editScheduleId, editRecurringScheduleId]);

  const fetchScheduleData = async () => {
    try {
      // Retrieve the schedule IDs from localStorage
      const editScheduleId = JSON.parse(
        localStorage.getItem("editScheduleIdOneTime")
      );
      const editRecurringScheduleId = JSON.parse(
        localStorage.getItem("editScheduleIdRecurring")
      );

      // Determine the operation and scheduleId based on which key exists
      let operation = "";
      let scheduleId = null;

      if (editScheduleId) {
        operation = "getschedule";
        scheduleId = editScheduleId;
      } else if (editRecurringScheduleId) {
        operation = "getrecurringschedule";
        scheduleId = editRecurringScheduleId;
      } else {
        return;
      }

      // Fetch data from the API
      const response = await fetch(
        `https://usfarmdata.org/fastapi/email_function/?operation=${operation}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Token}`,
          },
          body: JSON.stringify({ Userid: userId, Scheduleid: scheduleId }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      const data = await response.json();
      console.log(data, `${operation} data`);

      // Map response data to form initial values
      const initialValues =
        operation === "getschedule"
          ? {
              emailTemplate: data.Templateid,
              emailSubject: data.EmailSubject,
              Description: data.Description,
              scheduleName: data.Schedulename,
              Scheduleid: data.Scheduleid,
              fromName: data.FromName,
              fromEmail: data.Fromemail,
              rule: data.Ruleid,
              scheduleDateTime: dayjs(data.Scheduledatetime),
            }
          : {
              emailTemplate: data.Templateid,
              emailSubject: data.EmailSubject,
              Description: data.Description,
              scheduleName: data.Schedulename,
              Scheduleid: data.Scheduleid,
              fromName: data.FromName,
              fromEmail: data.Fromemail,
              rule: data.Ruleid,
              Startdate: dayjs(data.Startdate),
              Enddate: dayjs(data.Enddate),
              RunTime: dayjs(data.RunTime, "HH:mm:ss"),
            };

      form.setFieldsValue(initialValues);
      setInitialFormValues(initialValues);
    } catch (error) {
      message.error("Failed to fetch schedule data.");
      console.error("Fetch error:", error);
    }
  };

  const fetchTemplates = async () => {
    settembLoading(true);
    setError(null);

    try {
      const response = await fetch(
        "https://usfarmdata.org/fastapi/email_function/?operation=gettemplate",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Token}`,
          },
          body: JSON.stringify({}),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      const data = await response.json();
      setTemplates(data);
    } catch (error) {
      setError(error.message);
    } finally {
      settembLoading(false);
    }
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  const { dataGetRule, loadingGetRule, getRule } = useExecuteRule();

  useEffect(() => {
    getRule(userId);
  }, [userId]);

  // const handleNext = async () => {
  //   try {
  //     const values = await form.validateFields();
  //     if (editScheduleId) {
  //       values.Scheduleid = initialFormValues.Scheduleid;
  //     }
  //     if (isEqual(initialFormValues, values)) {
  //       message.error({
  //         content:
  //           "No changes detected. To go back to schedule history, click 'Cancel' if no updates are needed.",
  //       });
  //       return;
  //     }

  //     const scheduleDateTime = values.scheduleDateTime.format(
  //       "YYYY-MM-DDTHH:mm:ss"
  //     );
  //     values.scheduleDateTime = scheduleDateTime;

  //     setSelectedValues(values);
  //     setShowSelectedValues(true);

  //     if (!editScheduleId) {
  //       localStorage.setItem("scheduledData", JSON.stringify(values));
  //     } else {
  //       localStorage.setItem("EditScheduledData", JSON.stringify(values));
  //     }
  //   } catch (errorInfo) {
  //     console.log("Validation Failed:", errorInfo);
  //   }
  // };

  const handleNext = async () => {
    try {
      const values = await form.validateFields();

      if (editScheduleId || editRecurringScheduleId) {
        values.Scheduleid = initialFormValues.Scheduleid;
      }

      if (isEqual(initialFormValues, values)) {
        message.error({
          content:
            "No changes detected. To go back to schedule history, click 'Cancel' if no updates are needed.",
        });
        return;
      }
      if (values.scheduleDateTime) {
        values.scheduleDateTime = values.scheduleDateTime.format(
          "YYYY-MM-DDTHH:mm:ss"
        );
      }

      if (values.Startdate) {
        values.Startdate = values.Startdate.format("YYYY-MM-DD");
      }
      if (values.Enddate) {
        values.Enddate = values.Enddate.format("YYYY-MM-DD");
      }
      if (values.RunTime) {
        values.RunTime = values.RunTime.format("HH:mm:ss");
      }

      setSelectedValues(values);
      setShowSelectedValues(true);

      const storageKey =
        editScheduleId || editRecurringScheduleId
          ? "EditScheduledData"
          : "scheduledData";
      localStorage.setItem(storageKey, JSON.stringify(values));
    } catch (errorInfo) {
      console.log("Validation Failed:", errorInfo);
    }
  };

  const handlePreview = () => {
    setShowSelectedValues(false);
  };

  const { addSchedule, addScheduleLoading } = useAddSchedule();
  const { updateSchedule, editScheduleLoading } = useUpdateSchedule();

  // Function to construct regular schedule request data
  const constructRegularScheduleData = (scheduleData, userId) => {
    return {
      Templateid: scheduleData.emailTemplate,
      Ruleid: scheduleData.rule,
      Description: scheduleData.Description,
      Schedulename: scheduleData.scheduleName,
      Scheduledatetime: scheduleData.scheduleDateTime,
      CreatedUserId: userId,
      Fromemail: scheduleData.fromEmail,
      EmailSubject: scheduleData.emailSubject,
      FromName: scheduleData.fromName,
    };
  };

  // Function to construct recurring schedule request data
  const constructRecurringScheduleData = (scheduleData, userId) => {
    return {
      Templateid: scheduleData.emailTemplate,
      Ruleid: scheduleData.rule,
      Description: scheduleData.Description,
      Schedulename: scheduleData.scheduleName,
      Startdate: scheduleData.Startdate,
      Enddate: scheduleData.Enddate,
      RunTime: scheduleData.RunTime,
      CreatedUserId: userId,
      Fromemail: scheduleData.fromEmail,
      EmailSubject: scheduleData.emailSubject,
      FromName: scheduleData.fromName,
    };
  };

  const handleAddSchedule = async () => {
    const scheduleData = JSON.parse(localStorage.getItem("scheduledData"));
    if (!scheduleData) {
      message.error("No schedule data found in localStorage.");
      return;
    }

    let requestData;
    if (isRecurring) {
      requestData = constructRecurringScheduleData(scheduleData, userId);
    } else {
      requestData = constructRegularScheduleData(scheduleData, userId);
    }

    try {
      const operationType = isRecurring
        ? "addrecurringschedule"
        : "addschedule";

      console.log(operationType, "operationType");
      const result = await addSchedule(requestData, operationType);
      console.log("API response:", result);

      message.success(
        isRecurring
          ? "Recurring schedule added successfully!"
          : "Schedule added successfully!"
      );

      // Perform post-addition actions
      setActiveTab("history");
      handlePreview();
      form.resetFields();
    } catch (error) {
      console.error("Error in handleAddSchedule:", error.message);
      message.error(error.message || "Failed to add schedule.");
    }
  };

  // const handleAddSchedule = async () => {
  //   const scheduleData = JSON.parse(localStorage.getItem("scheduledData"));
  //   if (!scheduleData) {
  //     message.error("No schedule data found in localStorage.");
  //     return;
  //   }

  //   const requestData = {
  //     Templateid: scheduleData.emailTemplate,
  //     Ruleid: scheduleData.rule,
  //     Description: scheduleData.Description,
  //     Schedulename: scheduleData.scheduleName,
  //     Scheduledatetime: scheduleData.scheduleDateTime,
  //     CreatedUserId: userId,
  //     Fromemail: scheduleData.fromEmail,
  //     EmailSubject: scheduleData.emailSubject,
  //     FromName: scheduleData.fromName,
  //   };

  //   try {
  //     const result = await addSchedule(requestData);
  //     console.log("API response:", result);
  //     setActiveTab("history");
  //     handlePreview();
  //     form.resetFields();
  //   } catch (error) {
  //     console.error("Error in handleAddSchedule:", error.message);
  //     message.error(error.message || "Failed to add schedule.");
  //   }
  // };

  const handleUpdateSchedule = async () => {
    const editScheduleData = JSON.parse(
      localStorage.getItem("EditScheduledData")
    );
    if (!editScheduleData) {
      message.error("No schedule data found in localStorage.");
      return;
    }

    const editrequestData = {
      Templateid: editScheduleData.emailTemplate,
      Ruleid: editScheduleData.rule,
      Schedulename: editScheduleData.scheduleName,
      Description: editScheduleData.Description,
      Scheduleid: editScheduleData.Scheduleid,
      Scheduledatetime: editScheduleData.scheduleDateTime,
      CreatedUserId: editScheduleData.CreatedUserId,
      Fromemail: editScheduleData.fromEmail,
      EmailSubject: editScheduleData.emailSubject,
      FromName: editScheduleData.fromName,
    };

    try {
      const result = await updateSchedule(editrequestData);
      setActiveTab("history");
      handlePreview();
      form.resetFields();
      localStorage.removeItem("editScheduleId");
      console.log("API response:", result);
    } catch (error) {
      message.error("Something went wrong while Update the schedule.");
    }
  };

  const handleEdit = () => {
    setActiveTab("add");
    localStorage.removeItem("scheduledData");
  };

  const handleCancelEdit = () => {
    form.resetFields();
    message.info("Cancelled editing and returned to Scheduled History.");
    localStorage.removeItem("editScheduleIdOneTime");
    localStorage.removeItem("editScheduleIdRecurring");
    setActiveTab("history");
  };

  const isRecurringDisabled = !!editRecurringScheduleId; // true if editRecurringScheduleId exists
  const isOneTimeDisabled = !!editScheduleId; // true if editScheduleId exists

  return (
    <Container alignBox="row" className="gap-2">
      <Box
        flexible
        className="rounded-md bg-white"
        style={{
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
        }}
      >
        <Container className="gap-2">
          <div className="p-2 bg-white rounded-md">
            {/* Tabs */}
            <div className="flex border-b gap-4">
              <button
                className={`p-2 cursor-pointer transition-all duration-300 ease-in-out ${
                  activeTab === "history"
                    ? "border-b-2 border-blue-500 font-semibold text-slate-700"
                    : "border-b-2 border-transparent text-gray-500"
                }`}
                onClick={() => handleTabClick("history")}
              >
                Scheduled History
              </button>
              <button
                className={`p-2 cursor-pointer transition-all duration-300 ease-in-out ${
                  activeTab === "add"
                    ? "border-b-2 border-blue-500 font-semibold text-slate-700"
                    : "border-b-2 border-transparent text-gray-500"
                }`}
                onClick={() => handleTabClick("add")}
              >
                {editScheduleId
                  ? "Update Schedule"
                  : editRecurringScheduleId
                  ? "Update Recurring Schedule"
                  : "Add New Schedule"}
              </button>
            </div>
          </div>

          <Box flexible>
            {activeTab === "history" && <ScheduleTable onEdit={handleEdit} />}

            {activeTab === "add" && (
              <Container className="gap-2">
                {!showSelectedValues ? (
                  <>
                    <Box>
                      <div className="flex items-center gap-8 px-4">
                        <div
                          className={`px-4 py-2 border rounded-lg shadow-md hover:shadow-lg transition-shadow  ${
                            selectedView === "onetime"
                              ? "border-blue-500 border-2 bg-blue-50"
                              : "border-gray-300"
                          } ${
                            isRecurringDisabled
                              ? "opacity-50 cursor-not-allowed border-0"
                              : "cursor-pointer"
                          }`}
                          onClick={() => {
                            if (!isRecurringDisabled) {
                              handleViewChange({
                                target: { value: "onetime" },
                              });
                            }
                          }}
                        >
                          <input
                            type="radio"
                            value="onetime"
                            checked={selectedView === "onetime"}
                            onChange={handleViewChange}
                            className="hidden"
                            disabled={isRecurringDisabled}
                          />
                          <b className="text-lg font-semibold">
                            Add One-Time Schedule
                          </b>
                          <div className="text-gray-500 text-sm">
                            Schedule for a single occurrence.
                          </div>
                        </div>
                        <div
                          className={`px-4 py-2 border rounded-lg shadow-md hover:shadow-lg transition-shadow  ${
                            selectedView === "recurring"
                              ? "border-blue-500 border-2 bg-blue-50"
                              : "border-gray-300"
                          } ${
                            isOneTimeDisabled
                              ? "opacity-50 cursor-not-allowed border-0"
                              : "cursor-pointer"
                          }`}
                          onClick={() => {
                            if (!isOneTimeDisabled) {
                              handleViewChange({
                                target: { value: "recurring" },
                              });
                            }
                          }}
                        >
                          <input
                            type="radio"
                            value="recurring"
                            checked={selectedView === "recurring"}
                            onChange={handleViewChange}
                            className="hidden"
                            disabled={isOneTimeDisabled}
                          />
                          <b className="text-lg font-semibold">
                            Add Recurring Schedule
                          </b>
                          <div className="text-gray-500 text-sm">
                            Schedule for recurring events.
                          </div>
                        </div>
                      </div>
                    </Box>
                    <Box flexible scroll="vertical" className="p-4">
                      <Container alignBox="row" className="gap-3 w-full">
                        {selectedView === "onetime" ? (
                          <Form
                            form={form}
                            layout="vertical"
                            onFinish={handleSubmit}
                            initialValues={{
                              scheduleDate: dayjs(),
                              scheduleTime: dayjs(),
                            }}
                            // variant="filled"
                          >
                            <Row gutter={16}>
                              <Col span={12}>
                                <Form.Item
                                  label="Select Rule"
                                  name="rule"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select a rule",
                                    },
                                  ]}
                                >
                                  <Select
                                    placeholder="Select Rule"
                                    size="large"
                                    style={{ width: 300 }}
                                    loading={loadingGetRule}
                                  >
                                    {dataGetRule &&
                                      dataGetRule.map((rule) => (
                                        <Option
                                          key={rule.RuleId}
                                          value={rule.RuleId}
                                        >
                                          {`${rule.RuleName} (${rule.Counts})`}
                                        </Option>
                                      ))}
                                  </Select>
                                </Form.Item>
                              </Col>

                              <Col span={12}>
                                <Form.Item
                                  label="Select Email Template"
                                  name="emailTemplate"
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Please select an email template",
                                    },
                                  ]}
                                >
                                  <Select
                                    placeholder="Select Email Template"
                                    size="large"
                                    style={{ width: 300 }}
                                    loading={tembLoading}
                                  >
                                    {templates.map((template) => (
                                      <Option
                                        key={template.templateid}
                                        value={template.templateid}
                                      >
                                        {template.templatename}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                            </Row>

                            <Row gutter={16}>
                              <Col span={12}>
                                <Form.Item
                                  label="Email Subject"
                                  name="emailSubject"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter email subject",
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="Email Subject"
                                    size="large"
                                    style={{ width: 300 }}
                                  />
                                </Form.Item>
                              </Col>

                              <Col span={12}>
                                <Form.Item
                                  label="From Name"
                                  name="fromName"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter from name",
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="From Name"
                                    size="large"
                                    style={{ width: 300 }}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>

                            <Row gutter={16}>
                              <Col span={12}>
                                <Form.Item
                                  label="From Email"
                                  name="fromEmail"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter from email",
                                    },
                                    {
                                      type: "email",
                                      message: "Please enter a valid email",
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="From Email"
                                    size="large"
                                    style={{ width: 300 }}
                                  />
                                </Form.Item>
                              </Col>

                              <Col span={12}>
                                <Form.Item
                                  label="Schedule Name"
                                  name="scheduleName"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter schedule name",
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="Schedule Name"
                                    size="large"
                                    style={{ width: 300 }}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>

                            <Row gutter={16}>
                              <Col span={12}>
                                {" "}
                                {/* Adjusted span to take full width for a single DateTimePicker */}
                                <Form.Item
                                  label="Schedule Date and Time"
                                  name="scheduleDateTime"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select a date and time",
                                    },
                                  ]}
                                >
                                  <DatePicker
                                    className="w-full"
                                    size="large"
                                    showTime // This enables the time picker
                                    style={{ width: "100%" }} // Ensure full width
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col span={12}>
                                {" "}
                                {/* Adjusted span to take full width for a single DateTimePicker */}
                                <Form.Item
                                  label="Description"
                                  name="Description"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please Enter the Description.",
                                    },
                                  ]}
                                >
                                  <TextArea
                                    placeholder="Description"
                                    autoSize={{
                                      minRows: 3,
                                      maxRows: 5,
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Form>
                        ) : (
                          <Form
                            form={form}
                            layout="vertical"
                            onFinish={handleSubmit}
                            initialValues={{
                              scheduleDate: dayjs(),
                              scheduleTime: dayjs(),
                            }}
                            // variant="filled"
                          >
                            <Row gutter={16}>
                              <Col span={12}>
                                <Form.Item
                                  label="Select Rule"
                                  name="rule"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select a rule",
                                    },
                                  ]}
                                >
                                  <Select
                                    placeholder="Select Rule"
                                    size="large"
                                    style={{ width: 300 }}
                                    loading={loadingGetRule}
                                  >
                                    {dataGetRule &&
                                      dataGetRule.map((rule) => (
                                        <Option
                                          key={rule.RuleId}
                                          value={rule.RuleId}
                                        >
                                          {`${rule.RuleName} (${rule.Counts})`}
                                        </Option>
                                      ))}
                                  </Select>
                                </Form.Item>
                              </Col>

                              <Col span={12}>
                                <Form.Item
                                  label="Select Email Template"
                                  name="emailTemplate"
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Please select an email template",
                                    },
                                  ]}
                                >
                                  <Select
                                    placeholder="Select Email Template"
                                    size="large"
                                    style={{ width: 300 }}
                                    loading={tembLoading}
                                  >
                                    {templates.map((template) => (
                                      <Option
                                        key={template.templateid}
                                        value={template.templateid}
                                      >
                                        {template.templatename}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                            </Row>

                            <Row gutter={16}>
                              <Col span={12}>
                                <Form.Item
                                  label="Email Subject"
                                  name="emailSubject"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter email subject",
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="Email Subject"
                                    size="large"
                                    style={{ width: 300 }}
                                  />
                                </Form.Item>
                              </Col>

                              <Col span={12}>
                                <Form.Item
                                  label="From Name"
                                  name="fromName"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter from name",
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="From Name"
                                    size="large"
                                    style={{ width: 300 }}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>

                            <Row gutter={16}>
                              <Col span={12}>
                                <Form.Item
                                  label="From Email"
                                  name="fromEmail"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter from email",
                                    },
                                    {
                                      type: "email",
                                      message: "Please enter a valid email",
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="From Email"
                                    size="large"
                                    style={{ width: 300 }}
                                  />
                                </Form.Item>
                              </Col>

                              <Col span={12}>
                                <Form.Item
                                  label="Schedule Name"
                                  name="scheduleName"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter schedule name",
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="Schedule Name"
                                    size="large"
                                    style={{ width: 300 }}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>

                            <Row gutter={16}>
                              <Col span={12}>
                                {" "}
                                {/* Adjusted span to take full width for a single DateTimePicker */}
                                <Form.Item
                                  label="Schedule Start-Date"
                                  name="Startdate"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select a Start-Date",
                                    },
                                  ]}
                                >
                                  <DatePicker
                                    className="w-full"
                                    size="large"
                                    // This enables the time picker
                                    style={{ width: "100%" }} // Ensure full width
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                {" "}
                                {/* Adjusted span to take full width for a single DateTimePicker */}
                                <Form.Item
                                  label="Schedule End-Date"
                                  name="Enddate"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select a End-Date",
                                    },
                                  ]}
                                >
                                  <DatePicker
                                    className="w-full"
                                    size="large"
                                    // This enables the time picker
                                    style={{ width: "100%" }} // Ensure full width
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col span={12}>
                                {" "}
                                {/* Adjusted span to take full width for a single DateTimePicker */}
                                <Form.Item
                                  label="Schedule Run-Time"
                                  name="RunTime"
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Please select Schedule Run-Time",
                                    },
                                  ]}
                                >
                                  <TimePicker
                                    className="w-full"
                                    size="large"
                                    // This enables the time picker
                                    style={{ width: "100%" }} // Ensure full width
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col span={12}>
                                {" "}
                                {/* Adjusted span to take full width for a single DateTimePicker */}
                                <Form.Item
                                  label="Description"
                                  name="Description"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please Enter the Description.",
                                    },
                                  ]}
                                >
                                  <TextArea
                                    placeholder="Description"
                                    autoSize={{
                                      minRows: 3,
                                      maxRows: 5,
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Form>
                        )}
                      </Container>
                    </Box>
                    <Box className="p-2 border-t">
                      <div className="flex items-center gap-3"></div>
                      {(editScheduleId || editRecurringScheduleId) && (
                        <Button
                          onClick={handleCancelEdit}
                          className="mt-2 mr-4"
                        >
                          Cancel
                        </Button>
                      )}
                      <Button type="primary" onClick={handleNext}>
                        Next
                      </Button>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box flexible scroll="vertical" className="px-4 py-2">
                      <Container alignBox="row" className="gap-3 flex-wrap">
                        <div>
                          <div></div>
                          <PreviewTemplate />
                        </div>
                        <Box>
                          <PreviewRule />
                        </Box>
                        <Box
                          style={{
                            width: "390px",
                            height: "450px",
                            boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                          }}
                          className="rounded-lg pb-4"
                        >
                          <Container>
                            <div></div>
                            <h1 className="p-2 text-slate-700 text-lg font-semibold border-b">
                              Schedule Email Details
                            </h1>
                            <Box flexible scroll="vertical">
                              {selectedView === "onetime" ? (
                                <Container className="gap-2 max-w-sm p-3">
                                  <div>
                                    <div className="text-sm text-slate-800 mb-2">
                                      Email Subject
                                    </div>
                                    <div className="p-2 bg-gray-100 rounded-md">
                                      {selectedValues.emailSubject}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="text-sm text-slate-800 mb-2">
                                      From Name
                                    </div>
                                    <div className="p-2 bg-gray-100 rounded-md">
                                      {selectedValues.fromName}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="text-sm text-slate-800 mb-2">
                                      From Email
                                    </div>
                                    <div className="p-2 bg-gray-100 rounded-md">
                                      {selectedValues.fromEmail}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="text-sm text-slate-800 mb-2">
                                      Schedule Name
                                    </div>
                                    <div className="p-2 bg-gray-100 rounded-md">
                                      {selectedValues.scheduleName}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="text-sm text-slate-800 mb-2">
                                      Schedule Date Aand Time
                                    </div>
                                    <div className="p-2 bg-gray-100 rounded-md">
                                      {selectedValues.scheduleDateTime}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="text-sm text-slate-800 mb-2">
                                      Description
                                    </div>
                                    <div className="p-2 bg-gray-100 rounded-md">
                                      {selectedValues.Description}
                                    </div>
                                  </div>
                                </Container>
                              ) : (
                                <Container className="gap-2 max-w-sm p-3">
                                  <div>
                                    <div className="text-sm text-slate-800 mb-2">
                                      Email Subject
                                    </div>
                                    <div className="p-2 bg-gray-100 rounded-md">
                                      {selectedValues.emailSubject}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="text-sm text-slate-800 mb-2">
                                      From Name
                                    </div>
                                    <div className="p-2 bg-gray-100 rounded-md">
                                      {selectedValues.fromName}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="text-sm text-slate-800 mb-2">
                                      From Email
                                    </div>
                                    <div className="p-2 bg-gray-100 rounded-md">
                                      {selectedValues.fromEmail}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="text-sm text-slate-800 mb-2">
                                      Schedule Name
                                    </div>
                                    <div className="p-2 bg-gray-100 rounded-md">
                                      {selectedValues.scheduleName}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="text-sm text-slate-800 mb-2">
                                      Schedule Start-Date
                                    </div>
                                    <div className="p-2 bg-gray-100 rounded-md">
                                      {selectedValues.Startdate}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="text-sm text-slate-800 mb-2">
                                      Schedule End-Date
                                    </div>
                                    <div className="p-2 bg-gray-100 rounded-md">
                                      {selectedValues.Enddate}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="text-sm text-slate-800 mb-2">
                                      Schedule Run-Time
                                    </div>
                                    <div className="p-2 bg-gray-100 rounded-md">
                                      {selectedValues.RunTime}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="text-sm text-slate-800 mb-2">
                                      Description
                                    </div>
                                    <div className="p-2 bg-gray-100 rounded-md">
                                      {selectedValues.Description}
                                    </div>
                                  </div>
                                </Container>
                              )}
                            </Box>
                          </Container>
                        </Box>
                      </Container>
                    </Box>
                    <Box className="p-2 border-t">
                      <div className="flex gap-2 items-center">
                        <Button type="primary" onClick={handlePreview} ghost>
                          Back to Form
                        </Button>
                        {editScheduleId && (
                          <Button
                            type="primary"
                            onClick={handleUpdateSchedule}
                            loading={editScheduleLoading}
                          >
                            Update Schedule
                          </Button>
                        )}
                        {!editScheduleId && (
                          <Button
                            type="primary"
                            onClick={handleAddSchedule}
                            loading={addScheduleLoading}
                          >
                            Confirm and Schedule
                          </Button>
                        )}
                      </div>
                    </Box>
                  </>
                )}
              </Container>
            )}
          </Box>
        </Container>
      </Box>
    </Container>
  );
};

export default Schedule;
