import React, { useMemo, useRef, useState, useEffect } from "react";
import { Container, Box } from "@lib/Layout";
import { useNavigate } from "react-router-dom";
import CustomTable from "@components/CustomTable/CustomTable";
import {
  Table,
  DatePicker,
  Modal,
  Select,
  Spin,
  Empty,
  Button,
  message,
  Tabs,
  Drawer,
} from "antd";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
// import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
// import ContactMailIcon from "@mui/icons-material/ContactMail";
// import CampaignIcon from "@mui/icons-material/Campaign";
// import DeleteIcon from "@mui/icons-material/Delete";
// import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import InfoIcon from "@mui/icons-material/Info";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import CampaignIcon from "@mui/icons-material/Campaign";
import "./ListCenter.css";
import debounce from "lodash.debounce";
import Loading from "@components/Loading/Loading";
import { fetchUserList } from "../../../hooks/ListCenterHook/useFetchUserList";
import { fetchListData } from "../../../hooks/ListCenterHook/useFetchListData";
import { applyFilter } from "../../../hooks/ListCenterHook/useApplyFilter";
import { handleShareList } from "../../../hooks/ListCenterHook/useShareList";
import { handleDeleteList } from "../../../hooks/ListCenterHook/useDeleteList";
import { handleUnshareList } from "../../../hooks/ListCenterHook/useUnshareList";
import { fetchCustomerListData } from "../../../hooks/ListCenterHook/useFetchCustomerList";
import SelectWithRemoveUser from "../SelectWithRemoveUser/SelectWithRemoveUser";

import axios from "axios";
import TabPane from "antd/es/tabs/TabPane";
const { Option } = Select;
const ListCenter = () => {
  console.log("applyFilter:", applyFilter); // Keep this for debugging
  console.log("Type of applyFilter:", typeof applyFilter);
  const navigate = useNavigate();
  const { RangePicker } = DatePicker;
  const [messageApi, contextHolder] = message.useMessage();
  const dateFormat = "MM-DD-YYYY";
  const [chipLNvalues, setLNValue] = useState({
    lncriteria: "",
    lncriterias: [],
  });
  const [chipLCvalues, setLCValue] = useState({
    lccriteria: "",
    lccriterias: [],
  });
  const [chipLTvalues, setLTValue] = useState({
    ltcriteria: "",
    ltcriterias: [],
  });
  const [chipDatevalues, setDateValue] = useState({
    datecriteria: "",
    datecriterias: [],
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [txtlistname, setListtName] = useState("");
  const [txtlistcount, setListtCount] = useState("");
  const [txtlisttype, setListtType] = useState(null);
  const [selectedListNames, setSelectedListNames] = useState("");
  const [selectedListIDs, setSelectedListIDs] = useState("");
  const [apiTblData, setapiTblData] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectuservalue, setSelectUserValue] = useState([]);
  const [deleteListID, setDeleteListID] = useState("");
  const [unshareListID, setUnshareListID] = useState("");
  const [trigger, setTrigger] = useState(false); // Trigger state to rerun useEffect
  const callFunction = useRef(() => {});
  const [customerData, setCustomerData] = useState([]); // Ref to hold the function
  const [visible, setVisible] = useState(false);
  const [actionVisible, setActionVisible] = useState(false);

  const showDrawer = () => setVisible(true);
  const closeDrawer = () => setVisible(false);
  const closeVisible = () => setActionVisible(false);

  const ViewContactNavigate = (item) => {
    const { listid, sharedlistflag, liststatus, listname } = item;
    const currentList = {
      listid,
      sharedlistflag,
      liststatus,
      listname,
    };
    localStorage.setItem("currentlist", JSON.stringify(currentList));
    navigate("/contacts");
  };
  const listActionID = localStorage.getItem("listactionid");
  const Viewlistactions = (item) => {
    const { listid } = item;
    localStorage.setItem("listactionid", JSON.stringify(listid));
    setActionVisible(true);
  };

  const [logindata, setLoginData] = useState(() => {
    const savedLoginData = localStorage.getItem("logindata");
    return savedLoginData ? JSON.parse(savedLoginData) : null;
  });

  function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const fetchRef = useRef(0);
    const debounceFetcher = useMemo(() => {
      const loadOptions = (value) => {
        fetchRef.current += 1;
        const fetchId = fetchRef.current;
        setOptions([]);
        setFetching(true);
        fetchOptions(value).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return;
          }
          setOptions(newOptions);
          setFetching(false);
        });
      };
      return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);
    return (
      <Select
        labelInValue
        filterOption={false}
        onSearch={debounceFetcher}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        {...props}
        options={options}
      />
    );
  }

  const handleFetchList = () => {
    fetchListData(
      setLoading,
      setapiTblData,
      showUSLModal,
      showDLModal,
      ViewContactNavigate,
      Viewlistactions
    );
  };

  useEffect(() => {
    // Trigger the function when the page loads (component mounts)
    setTrigger(true);
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  useEffect(() => {
    if (trigger) {
      handleFetchList();
      setTrigger(false);
    }
  }, [trigger]);

  const showCustomerModal = (id) => {
    console.log("Show customer modal for ID:", id);
  };

  const deleteCustomerModal = (id) => {
    console.log("Delete customer modal for ID:", id);
  };

  const viewCustomerDetails = (item) => {
    console.log("View customer details:", item);
  };
  useEffect(() => {
    //customerlistdata
    const loadCustomerData = async () => {
      await fetchCustomerListData(
        setLoading,
        setCustomerData,
        showUSLModal,
        showDLModal,
        viewCustomerDetails
      );
    };

    loadCustomerData();
  }, []);

  //chips for filter
  const handleDateChange = (range) => {
    if (range) {
      const valueOfInput1 = range[0].format("MM-DD-YYYY");
      const valueOfInput2 = range[1].format("MM-DD-YYYY");

      setDateValue({
        datecriterias: [valueOfInput1 + " to " + valueOfInput2],
        datecriteria: "",
      });
    }
  };
  const handleLNChipChange = (name) => (event) => {
    setLNValue({ ...chipLNvalues, [name]: event.target.value });
    setListtName(event.target.value);
  };
  const handleLCChipChange = (name) => (event) => {
    setLCValue({ ...chipLCvalues, [name]: event.target.value });
    setListtCount(event.target.value);
  };
  const handleLTChipChange = (value) => {
    setLTValue({
      ltcriterias: [value],
      ltcriteria: "",
    });
    setListtType(value);
  };

  function LNpushToarray(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      setLNValue({
        lncriterias: [...chipLNvalues.lncriterias, e.target.value],
        lncriteria: "",
      });
      setListtName("");
    }
  }
  function LCpushToarray(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      setLCValue({
        lccriterias: [...chipLCvalues.lccriterias, e.target.value],
        lccriteria: "",
      });
      setListtCount("");
    }
  }

  const handleLNChipDelete = (item) => {
    var rm = chipLNvalues.lncriterias.indexOf(item);
    chipLNvalues.lncriterias.splice(rm, 1);
    setLNValue({ lncriterias: chipLNvalues.lncriterias });
  };
  const handleLCChipDelete = (item) => {
    var rm = chipLCvalues.lccriterias.indexOf(item);
    chipLCvalues.lccriterias.splice(rm, 1);
    setLCValue({ lccriterias: chipLCvalues.lccriterias });
  };
  const handleLTChipDelete = (item) => {
    var rm = chipLTvalues.ltcriterias.indexOf(item);
    chipLTvalues.ltcriterias.splice(rm, 1);
    setLTValue({ ltcriterias: chipLTvalues.ltcriterias });
  };
  const handleDateChipDelete = (item) => {
    var rm = chipDatevalues.datecriterias.indexOf(item);
    chipDatevalues.datecriterias.splice(rm, 1);
    setDateValue({ datecriterias: chipDatevalues.datecriterias });
  };
  //chips for filter

  //apply and clear filter
  const handleClearFilter = (e) => {
    setLNValue({ lncriteria: "", lncriterias: [] });
    setLCValue({ lccriteria: "", lccriterias: [] });
    setLTValue({ ltcriteria: "", ltcriterias: [] });
    setDateValue({ datecriteria: "", datecriterias: [] });
    setTrigger(true);
  };

  const handleApplyFilter = async () => {
    // Make handleApplyFilter async
    try {
      await applyFilter(
        // Use await here
        chipLNvalues,
        chipLCvalues,
        chipLTvalues,
        chipDatevalues,
        logindata,
        setLoading,
        setapiTblData,
        //setCustomerData,
        showUSLModal,
        showDLModal,
        ViewContactNavigate
      );
      // Any code you want to execute AFTER applyFilter completes successfully
      console.log("Filter applied successfully");
    } catch (error) {
      // Handle errors that occur within applyFilter
      console.error("Error applying filter in handleApplyFilter:", error);
      // Optionally, set some error state or display a message to the user
    }
  };
  //apply and clear filter

  //table functions
  const onSelectChange = (newSelectedRowKeys, newSelectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(newSelectedRows);

    if (newSelectedRows.length > 0) {
      const sListNames = newSelectedRows.reduce((arrval, item) => {
        // Extracting the 'children' value from 'listname.props.children.props.children'
        const listNameText =
          item.listname?.props?.children?.props?.children || "";

        return arrval ? `${arrval}, ${listNameText}` : `${listNameText}`;
      }, null);

      console.log(newSelectedRows, "newSelectedRows");

      const sListIDs = newSelectedRows.reduce(
        (arrvalkeys, item) =>
          arrvalkeys ? `${arrvalkeys}, ${item.key}` : `${item.key}`,
        null
      );

      setSelectedListIDs(sListIDs);
      setSelectedListNames(sListNames);
      setSelectUserValue(null);
    }
  };

  const handleGetCheckboxProps = (record) => {
    if (record.isshared) {
      return {
        disabled: true,
      };
    }
  };

  const locale = {
    emptyText: <Empty description="No Data"></Empty>,
  };

  const rowSelection = {
    selectedRowKeys,
    selectedRows,
    onChange: onSelectChange,
  };

  const hasShareSelected = selectedRowKeys.length >= 1;
  const hasMergeSelected = selectedRowKeys.length >= 2;

  const columns = [
    {
      title: "",
      dataIndex: "shared",
      width: 50,
      needSorting: false,
    },
    {
      title: "List Name",
      dataIndex: "listname",
      width: "40%",

      sorter: (a, b) => a.listname.length - b.listname.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Create Date",
      dataIndex: "listdate",
      width: 100,
      sorter: (a, b) => new Date(a.listdate) - new Date(b.listdate),
      sortDirections: ["descend", "ascend"],
    },
    // {
    //   title: "Deploy Postcard",
    //   dataIndex: "deploypostcard",
    //   width: 200,
    //   align: "center",
    //   needSorting: false,
    // },
    // {
    //   title: "Marketing Campaign",
    //   dataIndex: "campaign",
    //   width: 200,
    //   align: "center",
    //   needSorting: false,
    // },
    {
      title: "Unshare List",
      dataIndex: "cancelshare",
      width: 100,
      align: "center",
      needSorting: false,
    },
    {
      title: "viewlist",
      dataIndex: "viewlist",
      align: "center",
      width: 50,
      needSorting: false,
    },
    {
      title: "Delete",
      dataIndex: "removelist",
      align: "center",
      fixed: "right",
      width: 50,
      needSorting: false,
    },
  ];

  // const PrimarySharedList = () => {
  //   return (
  //     <div className="flex items-center gap-1">
  //       <div className="rounded-full h-5 w-5 bg-green-600 text-white">
  //         <span className="flex items-center justify-center text-sm">S</span>
  //       </div>
  //     </div>
  //   );
  // };

  // const SecondarySharedList = () => {
  //   return (
  //     <div className="flex items-center gap-1">
  //       <div className="rounded-full h-5 w-5 bg-red-600 text-white">
  //         <Tooltip title="Shared List">
  //           <span className="flex items-center justify-center text-sm">S</span>
  //         </Tooltip>
  //       </div>
  //     </div>
  //   );
  // };
  //table functions

  //share list
  const [isSLModalOpen, setIsSLModalOpen] = useState(false);
  const showSLModal = () => {
    setErrorMessage("");
    setConfirmLoading(false);
    setIsSLModalOpen(true);
  };
  const handleSLOk = async () => {
    try {
      await handleShareList({
        logindata,
        selectuservalue,
        selectedListIDs,
        setErrorMessage,
        setConfirmLoading,
        setTrigger,
        setIsSLModalOpen,
        messageApi,
      });
    } catch (error) {
      console.error("Error in handleSLOk:", error.message);
    }
  };
  const handleSLCancel = () => {
    setErrorMessage("");
    setSelectedRowKeys([]);
    setConfirmLoading(false);
    setIsSLModalOpen(false);
  };
  //share list

  //merge list
  const [isMLModalOpen, setIsMLModalOpen] = useState(false);
  const showMLModal = () => {
    setIsMLModalOpen(true);
  };
  const handleMLOk = () => {
    setIsMLModalOpen(false);
  };
  const handleMLCancel = () => {
    setIsMLModalOpen(false);
  };
  //merge list

  //delete list
  const [isDLModalOpen, setIsDLModalOpen] = useState(false);
  const showDLModal = (dellistid) => {
    setDeleteListID(dellistid);
    setConfirmLoading(false);
    setIsDLModalOpen(true);
  };
  const handleDLOk = async () => {
    try {
      await handleDeleteList({
        logindata,
        deleteListID,
        setTrigger,
        setDeleteListID,
        setConfirmLoading,
        setIsDLModalOpen,
        messageApi,
        setErrorMessage,
      });
    } catch (error) {
      console.error("Error in handleDLOk:", error.message);
    }
  };
  const handleDLCancel = () => {
    setDeleteListID("");
    setConfirmLoading(false);
    setIsDLModalOpen(false);
  };
  //delete list

  //unshare list
  const [isUSLModalOpen, setIsUSLModalOpen] = useState(false);
  const showUSLModal = (usllistid) => {
    setUnshareListID(usllistid);
    setConfirmLoading(false);
    setIsUSLModalOpen(true);
  };
  const handleUSLOk = async () => {
    try {
      await handleUnshareList({
        logindata,
        unshareListID,
        setTrigger,
        setUnshareListID,
        setConfirmLoading,
        setIsUSLModalOpen,
        messageApi,
        setErrorMessage,
      });
    } catch (error) {
      console.error("Error in handleUSLOk:", error.message);
    }
  };
  const handleUSLCancel = () => {
    setUnshareListID("");
    setConfirmLoading(false);
    setIsUSLModalOpen(false);
  };
  //unshare list

  //fetusers for dropdown
  const handleFetchUsers = async (username) => {
    const users = await fetchUserList(username);
    console.log(users);
    return users;
  };
  //fetusers for dropdown
  const handleReload = () => handleFetchList();
  return (
    <Container
      alignBox="column"
      className="bg-sky-50 gap-3 py-5 justify-center items-center"
    >
      {contextHolder} {/* Required to display the message */}
      <Box
        className="bg-white w-11/12 rounded-lg shadow-md"
        flexible={loading ? true : false}
      >
        {loading ? (
          <div className="flex justify-center items-center h-full">
            <Loading />
          </div>
        ) : (
          <Container>
            <Box className="p-3">
              <Container align="vertical" alignBox="row" isCover={false}>
                <Box flexible className=" text-lg "></Box>
                <Box></Box>
              </Container>
              <Box>
                <Tabs
                  defaultActiveKey="1"
                  tabBarExtraContent={
                    <div className="flex gap-2 items-center">
                      <Button type="primary" onClick={showDrawer}>
                        Filter
                      </Button>
                      <Button
                        type="primary"
                        onClick={showSLModal}
                        disabled={!hasShareSelected}
                      >
                        Share Lists
                      </Button>
                      <Button
                        type="primary"
                        onClick={showMLModal}
                        disabled={!hasMergeSelected}
                      >
                        Merge
                      </Button>
                    </div>
                  }
                >
                  <TabPane tab="DataBase List" key="1">
                    <CustomTable
                      onSelectChange={onSelectChange}
                      columns={columns}
                      data={apiTblData}
                      needActionColumn={false}
                      heightOffset={250}
                      getCheckboxProps={handleGetCheckboxProps}
                    />
                  </TabPane>

                  <TabPane tab="Customer List" key="2">
                    <CustomTable
                      onSelectChange={onSelectChange}
                      columns={columns}
                      data={customerData}
                      needActionColumn={false}
                      heightOffset={250}
                      getCheckboxProps={handleGetCheckboxProps}
                    />
                  </TabPane>
                  <TabPane tab="Uploaded List" key="3" disabled></TabPane>
                </Tabs>
              </Box>
            </Box>
          </Container>
        )}
      </Box>
      <Modal
        title="Share Lists"
        okText="Share"
        open={isSLModalOpen}
        onOk={handleSLOk}
        confirmLoading={confirmLoading}
        onCancel={handleSLCancel}
      >
        <div className="flex flex-row basis-full border-t">
          <div class="w-full gap-4 p-2">
            <div className="mb-6">
              <label
                for="txtSLSelectedList"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Selected List(s)
              </label>
              <textarea
                id="txtSLSelectedList"
                rows="4"
                name="txtSLSelectedList"
                value={selectedListNames}
                class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 border-b border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                disabled
                readonly
              ></textarea>
            </div>
            <div>
              <label
                for="txtSLSelectedUsertoShare"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Add User(s) to selected list(s)
              </label>
              <DebounceSelect
                id="txtSLSelectedUsertoShare"
                name="txtSLSelectedUsertoShare"
                mode="multiple"
                value={selectuservalue}
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Search Users to share"
                fetchOptions={handleFetchUsers}
                onChange={(newValue) => {
                  setSelectUserValue(newValue);
                }}
                className="border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full"
              />
            </div>
            <div className="text-sm">
              {errorMessage && (
                <span className="text-red-600">{errorMessage}</span>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        title="Merge Lists"
        okText="Save"
        open={isMLModalOpen}
        onOk={handleMLOk}
        onCancel={handleMLCancel}
      >
        <div className="flex flex-row basis-full border-t">
          <div class="w-full gap-4 p-2">
            <div className="mb-6">
              <label
                for="txtMLSelectedList"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Selected Lists
              </label>
              <textarea
                id="txtMLSelectedList"
                rows="4"
                name="txtMLSelectedList"
                value={selectedListNames}
                class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 border-b border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                disabled
                readonly
              ></textarea>
            </div>
            <div className="mb-6">
              <label
                for="txtMLListName"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                ListName
              </label>
              <input
                type="text"
                id="txtMLListName"
                name="txtMLListName"
                required
                placeholder="Type Listname to Merge"
                className="border-b border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              ></input>
            </div>
            <div>
              <p className="text-sm mergeHelpText">
                <InfoIcon fontSize="small"></InfoIcon> After the merge, the
                original lists will no longer appear in your list management
                area, and this cannot be reverted. Are you sure?
              </p>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        title="Delete List"
        okText="Delete"
        open={isDLModalOpen}
        onOk={handleDLOk}
        onCancel={handleDLCancel}
        confirmLoading={confirmLoading}
      >
        <p>Are you sure you want delete this list?</p>
      </Modal>
      <Modal
        title="Unshare The List"
        okText="Unshare"
        open={isUSLModalOpen}
        onOk={handleUSLOk}
        onCancel={handleUSLCancel}
        confirmLoading={confirmLoading}
      >
        <p>Are you sure you want unshare this list from users?</p>
      </Modal>
      <Drawer
        title="Apply Filters"
        placement="right"
        onClose={closeDrawer}
        open={visible}
        width={400}
        maskClosable={true}
        mask={false}
      >
        <Container>
          <Box flexible scroll="vertical">
            <Container className="gap-5">
              <div className="flex flex-col gap-4">
                <input
                  type="text"
                  name="txtlistname"
                  value={txtlistname}
                  onChange={handleLNChipChange("lncriteria")}
                  onKeyDown={(e) => LNpushToarray(e)}
                  className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
                  placeholder="List Name"
                />
                <input
                  type="text"
                  id="txtlistcount"
                  value={txtlistcount}
                  onChange={handleLCChipChange("lccriteria")}
                  onKeyDown={(e) => LCpushToarray(e)}
                  className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
                  placeholder="List Count"
                />
                <Select
                  placeholder="Select List Type"
                  value={txtlisttype}
                  onChange={handleLTChipChange}
                  size="large"
                >
                  <Option value="Shared Lists">Shared Lists</Option>
                  <Option value="My Lists">My Lists</Option>
                  <Option value="All Lists">All Lists</Option>
                </Select>
                <RangePicker
                  className="border border-gray-300 text-gray-900 text-sm rounded-lg p-2"
                  format={dateFormat}
                  id={{
                    start: "listfromdate",
                    end: "listtodate",
                  }}
                  allowClear="true"
                  onChange={handleDateChange}
                />
              </div>
              <div className="flex flex-wrap gap-3">
                {chipLNvalues.lncriterias &&
                  chipLNvalues.lncriterias.map((lncriteria, id) => (
                    <Chip
                      key={id}
                      label={lncriteria}
                      onDelete={() => handleLNChipDelete(lncriteria)}
                    />
                  ))}
                {chipLCvalues.lccriterias &&
                  chipLCvalues.lccriterias.map((lccriteria, id) => (
                    <Chip
                      key={id}
                      label={lccriteria}
                      onDelete={() => handleLCChipDelete(lccriteria)}
                    />
                  ))}
                {chipLTvalues.ltcriterias &&
                  chipLTvalues.ltcriterias.map((ltcriteria, id) => (
                    <Chip
                      key={id}
                      label={ltcriteria}
                      onDelete={() => handleLTChipDelete(ltcriteria)}
                    />
                  ))}
                {chipDatevalues.datecriterias &&
                  chipDatevalues.datecriterias.map((datecriteria, id) => (
                    <Chip
                      key={id}
                      label={datecriteria}
                      onDelete={() => handleDateChipDelete(datecriteria)}
                    />
                  ))}
              </div>
            </Container>
          </Box>
          <Box>
            <Container alignBox="row" className="gap-3">
              <Button onClick={handleClearFilter}>Clear All</Button>
              <Button
                type="primary"
                onClick={handleApplyFilter}
                icon={<FilterAltIcon fontSize="small"></FilterAltIcon>}
              >
                Apply Filter
              </Button>
            </Container>
          </Box>
        </Container>
      </Drawer>
      <Drawer
        title="List Actions"
        placement="right"
        onClose={closeVisible}
        open={actionVisible}
        width={550}
        maskClosable={true}
        mask={false}
        // footer={
        //   <Box>
        //     <Container alignBox="row" className="gap-3">
        //       <Button
        //         type="primary"
        //         ghost
        //         icon={<ContactMailIcon className="text-green-500" />}
        //       >
        //         Deploy Postcard
        //       </Button>
        //       <Button
        //         type="primary"
        //         ghost
        //         icon={<CampaignIcon className="text-orange-500" />}
        //       >
        //         Email Campaign
        //       </Button>
        //     </Container>
        //   </Box>
        // }
      >
        <Container>
          <Box>
            <div className="h-full">
              <SelectWithRemoveUser
                listId={listActionID}
                refrshfun={handleReload}
              />
              <Box>
                <Container alignBox="row" className="gap-3 py-8 justify-center">
                  <div className="cursor-pointer">
                    <div class="bg-white border border-gray-200 rounded-lg shadow-md p-3 transform transition duration-300 hover:scale-105 hover:shadow-lg">
                      <div class="flex items-center ">
                        <div class="p-2 rounded-full  text-white animate-pulse">
                          <ContactMailIcon className="text-green-700" />
                        </div>
                        <div>
                          <div class="text-lg font-semibold text-slate-500">
                            Deploy Postcard
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="cursor-pointer">
                    <div class="bg-white border border-gray-200 rounded-lg shadow-md p-3 transform transition duration-300 hover:scale-105 hover:shadow-lg">
                      <div class="flex items-center ">
                        <div class="p-2 rounded-full  text-white animate-pulse">
                          <CampaignIcon className="text-orange-700" />
                        </div>
                        <div>
                          <div class="text-lg font-semibold text-slate-500">
                            Email Campaign
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Container>
              </Box>
            </div>
          </Box>
        </Container>
      </Drawer>
    </Container>
  );
};

export default ListCenter;
