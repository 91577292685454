// hooks/useDeleteList.js
export const handleDeleteList = async ({
    logindata,
    deleteListID,
    setTrigger,
    setDeleteListID,
    setConfirmLoading,
    setIsDLModalOpen,
    messageApi,
    setErrorMessage,
  }) => {
    try {
      if (deleteListID !== "") {
        setConfirmLoading(true);
        const apiToken = logindata?.accessToken;
  
        const response = await fetch(
          "https://usfarmdata.org/fastapi/list_function/?operation=delete",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              accept: "application/json",
              Authorization: `Bearer ${apiToken}`,
            },
            body: JSON.stringify({
              listid: deleteListID,
            }),
          }
        );
  
        if (response.ok) {
          const result = await response.json();
          messageApi.open({
            type: "warning",
            content: "List deleted successfully.",
            className: "custom-message",
          });
          return result;
        } else {
          const errorText = await response.text();
          console.log(errorText);
          throw new Error("Server Error, please try again later.");
        }
      }
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Server Error, please try again later.",
        className: "custom-message",
      });
      throw error;
    } finally {
      setTrigger(true);
      setDeleteListID("");
      setConfirmLoading(false);
      setIsDLModalOpen(false);
      setErrorMessage("");
    }
  };
  