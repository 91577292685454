import React, { useState, useEffect } from "react";
import { Container, Box } from "@lib/Layout";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, message, Tooltip, Modal } from "antd";
import Style from "./Template.module.css";
const { confirm } = Modal;
const EmailTemplate = () => {
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [activeTemplate, setActiveTemplate] = useState(null);
  const navigate = useNavigate();
  console.log(activeTemplate, "activeTemplate");
  // Fetch templates
  const fetchTemplates = async () => {
    const loginData = JSON.parse(localStorage.getItem("logindata"));
    const Token = loginData ? loginData.accessToken : null;

    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        "https://usfarmdata.org/fastapi/email_function/?operation=gettemplate",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Token}`,
          },
          body: JSON.stringify({}),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      const data = await response.json();
      setTemplates(data);
      // Set the first template as active by default
      if (data.length > 0) {
        setActiveTemplate(data[1]);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Delete template
  const handleDeleteTemplate = async (templateId) => {
    const loginData = JSON.parse(localStorage.getItem("logindata"));
    const Token = loginData ? loginData.accessToken : null;
    setDeleteLoading(true);
    message.loading({ content: "Deleting template...", key: "delete" });

    try {
      const response = await fetch(
        `https://usfarmdata.org/fastapi/email_function/?operation=deletetemplate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Token}`,
          },
          body: JSON.stringify({ TemplateId: templateId }),
        }
      );

      const result = await response.json();
      if (!response.ok) {
        handleError(response, result);
      } else {
        message.success({
          content: result.message || "Template deleted successfully",
          key: "delete",
        });
        fetchTemplates();
      }
    } catch (error) {
      handleError(null, error);
    } finally {
      setDeleteLoading(false);
    }
  };

  const confirmDelete = (templateId) => {
    confirm({
      title: "Are you sure you want to delete this template?",
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDeleteTemplate(templateId);
      },
      onCancel() {
        message.info("Delete action canceled.");
      },
    });
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  const handleClick = () => {
    navigate("/createtemplate");
  };

  const handleError = (response, result) => {
    // Handle different HTTP status codes
    if (response) {
      if (response.status === 400) {
        message.error(result.detail || "Bad Request");
      } else if (response.status === 404) {
        message.error(result.detail || "Template not found");
      } else if (response.status === 500) {
        message.error(result.detail || "Internal Server Error");
      } else {
        message.error(result.detail || "An unexpected error occurred");
      }
    } else {
      // Catch any unexpected errors (network issues, invalid JSON, etc.)
      message.error(`An unexpected error occurred: ${result.detail || result}`);
    }
  };

  const generateBlobUrl = (htmlContent) => {
    const blob = new Blob([htmlContent], { type: "text/html" });
    return URL.createObjectURL(blob);
  };

  // Function to handle template preview click
  const handleTemplateClick = (template) => {
    setActiveTemplate(template);
  };

  return (
    <Container className="gap-2 bg-white p-2  rounded-md">
      <Box>
        <div
          className="py-2 px-4 bg-white rounded-md"
          style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
        >
          <div className="flex items-center">
            <div className="grow text-lg font-semibold">Email Templates</div>
            <div>
              <Button type="primary" ghost onClick={handleClick}>
                Create New Template
              </Button>
            </div>
          </div>
        </div>
      </Box>
      <Box flexible className="px-2 py-2 bg-sky-50">
        <Container
          className="gap-4 "
          alignBox="row"
          align={loading ? "both" : ""}
        >
          {loading ? (
            <div className="h-full flex flex-col gap-2 justify-center items-center">
              <div role="status">
                <svg
                  aria-hidden="true"
                  className="inline w-8 h-8 text-gray-200 animate-spin fill-green-500"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              </div>
              <span className="text-slate-600">
                Fetching Templates... Please wait.
              </span>
            </div>
          ) : error ? (
            <div className="h-full flex flex-col gap-2 justify-center items-center">
              <span className="text-red-600">{error}</span>
            </div>
          ) : templates.length > 0 ? (
            <Box flexible scroll="vertical">
              <Container alignBox="row" className="gap-2">
                <Box
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
                  }}
                  className="rounded-md bg-white"
                >
                  <Container className="gap-2 p-2" scroll="vertical">
                    {templates.map((template) => (
                      <Box
                        key={template.templateid}
                        style={{
                          width: "300px",
                          boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                        }}
                        className={`${Style.templateBox} rounded-lg relative ${
                          activeTemplate?.templateid === template.templateid
                            ? "bg-blue-100"
                            : "hover:bg-blue-50"
                        } transition duration-300 ease-in-out`}
                      >
                        <Container
                          style={{
                            overflow: "hidden",
                            cursor: "pointer",
                            justifyContent: "space-between",
                          }}
                          className="gap-2"
                          onClick={() => handleTemplateClick(template)}
                        >
                          <div className=" rounded-lg p-2 ">
                            <div className="text-slate-950 font-semibold">
                              {template.templatename}
                            </div>
                            <p className="text-xs">
                              Created Date:{" "}
                              {new Date(
                                template.createddate
                              ).toLocaleDateString()}
                            </p>
                            <p className="text-xs">
                              Created by: {template.createdusername}
                            </p>
                            <a
                              href={generateBlobUrl(template.templatetxt)}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: "#007BFF",
                                textDecoration: "underline",
                              }}
                              className="text-xs"
                            >
                              View Template
                            </a>
                            <span
                              className={`${Style.deleteBox} flex items-center justify-center p-1 rounded-md bg-red-200`}
                            >
                              <Tooltip title="Delete">
                                <DeleteIcon
                                  onClick={() =>
                                    confirmDelete(template.templateid)
                                  }
                                  className={Style.deleteIcon}
                                />
                              </Tooltip>
                            </span>
                          </div>
                        </Container>
                      </Box>
                    ))}
                  </Container>
                </Box>

                <Box
                  flexible
                  scroll="vertical"
                  style={{
                    boxShadow:
                      " rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
                  }}
                  className={`${Style.previewBox} "bg-white rounded-md p-4"`}
                >
                  {activeTemplate ? (
                    <Container>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: activeTemplate.templatetxt,
                        }}
                      />
                    </Container>
                  ) : (
                    <div>No template selected.</div>
                  )}
                </Box>
              </Container>
            </Box>
          ) : (
            <div className="h-full flex flex-col gap-2 justify-center items-center">
              <span className="text-gray-500">No templates available.</span>
            </div>
          )}
        </Container>
      </Box>
    </Container>
  );
};

export default EmailTemplate;
